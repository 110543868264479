import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').attributes['content'].value,
    'X-Key-Inflection': 'camel',
  }
});

axiosInstance.interceptors.request.use(
  function (config) {
    const company = getCompanyFromStorage();
    const workspace = getWorkspaceFromStorage();
    let companyId, workspaceId;
    if (company) {
      companyId = company.id
    } else if (Vue.prototype.$currentCompanyId) {
      companyId = Vue.prototype.$currentCompanyId
    } else {
      const cookies = document.cookie.split(";");
      cookies.forEach((cookie) => {
        if (cookie.includes("company")) {
          const parts = cookie.split("=");
          companyId = parseInt(parts[1]);
        }
      })
    }

    if (!workspace) {
      const cookies = document.cookie.split("; ");
      const workspaceCookie = cookies.find((cookie) => cookie.startsWith("workspace"));
      if (workspaceCookie) {
        workspaceId = parseInt(workspaceCookie.split("=")[1]);
      }
    }

    if (companyId) {
      config.headers['X-Genuity-Company-Id'] = `${companyId}`;
    }
    if (workspace || workspaceId) {
      config.headers['X-Genuity-Workspace-Id'] = `${workspace?.id || workspaceId}`;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if ( error.response != undefined && error.response.status == 401 ) {
    var modal = document.getElementById("sessionTimeoutModal");
    modal.style.display = "block";
    return new Promise(() => {});
  } else if (!error.response && error.message == "Network Error") {
    var modal = document.getElementById("networkErrorModal");
    modal.style.display = "block";
    return new Promise(() => {});
  } else {
    return Promise.reject(error);
  }

});

export default axiosInstance
